//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAu6pg5BhJXk9fUqZHB00xPcMVHGsLyZ2Q",
  authDomain: "eth2-c0fe1.firebaseapp.com",
  projectId: "eth2-c0fe1",
  storageBucket: "eth2-c0fe1.appspot.com",
  messagingSenderId: "153214167712",
  appId: "1:153214167712:web:a2918b634a34a9a0e5b7c5",
  measurementId: "G-G5QRTTT6XK",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
